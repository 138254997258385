import React, {FC, useState, createContext, useRef} from 'react';
import {MonthData, PaginatedList, PopularMap} from '../services/users';
type DataContext = {
  monthData: [monthData: MonthData | null, setMonthData: React.Dispatch<React.SetStateAction<MonthData>>]
  popularMap: [popularMap: PopularMap | null, setPopularMap: React.Dispatch<React.SetStateAction<PopularMap>>]
  activeUserPaginatedList: React.MutableRefObject<PaginatedList>
  newBiePaginatedList: React.MutableRefObject<PaginatedList>
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataContext = createContext<DataContext>(
  {} as DataContext
);

export const DataProvider: FC = ({ children }) => {
  const [monthData, setMonthData] = useState<MonthData | null>(null);
  const [popularMap, setPopularMap] = useState<PopularMap | null>(null);
  const activeUserPaginatedList = useRef<PaginatedList>(null);
  const newBiePaginatedList = useRef<PaginatedList>(null);

  return (
    <DataContext.Provider
      value={{
        monthData: [monthData, setMonthData],
        popularMap: [popularMap, setPopularMap],
        activeUserPaginatedList,
        newBiePaginatedList,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
