import { useRoutes, useNavigate } from 'react-router-dom';
import router from 'src/router';
import './styles/custom.scss';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {useContext, useEffect} from 'react';
import {auth} from './firebase-config';
import {isEmailAllowed} from './helpers';
import {UserContext} from './contexts/UserContext';

function App() {
  const [user, setUser] = useContext(UserContext).user;
  const content = useRoutes(router(!!user));
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if(!user) {
        setUser(null);
        navigate('/');
        return;
      }

      const _isEmailAllowed = isEmailAllowed(user.email);
      if(!_isEmailAllowed) {
        return auth.signOut();
      }

      setUser({
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        phone: user.phoneNumber,
        profile_url: user.photoURL
      })
    })
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
