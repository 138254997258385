import {FC, useState, createContext, Dispatch} from 'react';

type User = {
  uid: string;
  email: string;
  name: string;
  phone: string;
  profile_url: string;
} | null;

type UserContext = {
  user: [User, Dispatch<User>];
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<UserContext>(
  {} as UserContext
);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>(null);

  return (
    <UserContext.Provider
      value={{
        user: [user, setUser]
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
