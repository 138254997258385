import {castIndianTime} from './getIndianTime';

export function isEmailAllowed(email) {
	return email.endsWith('@pustack.com');
}

export async function getLastWeekDate() {
	const ist = await castIndianTime(true);
	return ist.getTime() - (7 * 24 * 60 * 60 * 1000);
}
